export const Blob1 = () => {
  return (
    <div className="blob-wrapper blob1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 347.5 237.5"
        width="262"
      >
        <path>
          <animate
            attributeName="d"
            values="M41,35C74.7,45.3,79.6,0,117.1,0c38.4,0,35.8,30.7,70.9,41c45.9,13.5,62.7-9,102,2.3
	c14.5,4.1,36.1,21.8,41,46.7c5.3,27.3-6.9,58.6-50,70c-30.8,8.1-48.1-9.1-75,6c-19.6,11-16.1,27-37.6,31.7
	c-25.1,5.4-31.9-19.6-72.4-18.7c-20.1,0.5-23.3,3.7-39,3c-21.9-0.9-39.5-15.4-38-36c1.2-17.1,11.9-23.2,8-39
	C23.4,92.2,9.7,93.2,3,77c-7.5-18.4,0-37,13-42C23.9,32,29,31.3,41,35z;

M61.5,51.6c33.7,10.3,47.3,5.8,74.2-20.3c20.5-19.8,49.9-42.9,81.5-24.8c30,17.2-1.6,48.7,29.7,75
	c15.9,13.4,50.3,1.4,74.3,9.8c32.3,11.4,40.6,68.7-7.9,85.2c-30.1,10.2-63.8-19.6-86.5-0.5c-17.2,14.5,10.8,49.7-20.1,59.9
	c-34,11.2-30.3-40-70.9-39.1c-20.1,0.5-52.5,35.5-72.6,22.6c-19.6-12.5,7.1-42.7-15.9-57c-14.6-9.1-38.6-2.5-46.1-26.6
	c-6.2-20,14.1-26.5,22.3-42.1c9.3-17.6-17.9-34.6,2.5-48.7C37.8,36.7,49.5,48,61.5,51.6z;

M41,35C74.7,45.3,79.6,0,117.1,0c38.4,0,35.8,30.7,70.9,41c45.9,13.5,62.7-9,102,2.3
	c14.5,4.1,36.1,21.8,41,46.7c5.3,27.3-6.9,58.6-50,70c-30.8,8.1-48.1-9.1-75,6c-19.6,11-16.1,27-37.6,31.7
	c-25.1,5.4-31.9-19.6-72.4-18.7c-20.1,0.5-23.3,3.7-39,3c-21.9-0.9-39.5-15.4-38-36c1.2-17.1,11.9-23.2,8-39
	C23.4,92.2,9.7,93.2,3,77c-7.5-18.4,0-37,13-42C23.9,32,29,31.3,41,35z
    "
            dur="8s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
  );
};

export const Blob2 = () => {
  return (
    <div className="blob-wrapper blob2">
      <svg version="1.1" x="0px" y="0px" viewBox="0 0 450 276.6" width="450">
        <path>
          <animate
            attributeName="d"
            values="
M54.6,67.9c24.5-7.1,4.1-31.5,36.1-48.5c18.8-10,36.8,15.3,50.1,5.9C161.9,10.5,193-8.3,218.2,3.9
	c33,16,1.3,40.5,32.5,41.3c21.7,0.5,31.8-28.9,63-28.9c37.2,0,37.2,25.6,52.1,38.2c9.8,8.3,56.2,25.9,61,47
	c6.1,26.7-0.1,72.3-22.8,84.6c-47.9,26.2-63.1-7.9-85.2,24.3c-18.7,27.3-2.6,43.4-32,58.8c-16.2,8.5-35.5,12-58.3-2.6
	c-38.1-24.4-39.2-27.4-60.4-31.5c-13.9-2.7-37.5,4.2-52.6,24.8c-13.1,17.9-61.5,24.9-84.1,1.3C16.6,245.8,8.1,218.9,10.7,197
	C14.6,164.2,0,170.2,0,151.3c0-25.8,27.6-28.3,29.2-42.8c2.4-21.1-8.6-25.3-3.9-32.5C30.1,68.6,34.1,73.8,54.6,67.9z;

M60.7,17.3C84.9,9.6,98.5,17,108.2,23c14,8.7,31.1,16.6,44.4,7.2c21.2-14.9,17.5-26.3,39.7-29.6
	c18.9-2.8,24.8,4.2,48.6,24.4c10.3,8.8,33.8,24.8,65,24.8c37.2,0,48-6.2,73.4-6.4c12.8-0.1,39.1,2.9,48.4,22.4
	c11.4,23.7,4.9,45.4-12.2,67.1c-33.7,42.9-40.5,14.5-58,68.7c-12.7,39.2-54.3-6.9-84.1,7.7c-13,6.4-4,32.5-35.6,35.6
	c-36.6,3.6-29.4-28.4-58.3-38.7c-18.2-6.5-32-2.6-49,13.9c-16,15.5-52.7,21.8-75.4-1.8c-14.8-15.4-5-20.3-30.9-32.5
	C6.9,177.8-1.8,169.8,0.3,151c3.1-28.4,27.4-17.5,41.8-45.4c10.8-20.8,0.1-27.4-6.2-41.8C30.7,51.9,39.2,24.2,60.7,17.3z;

M54.6,67.9c24.5-7.1,4.1-31.5,36.1-48.5c18.8-10,36.8,15.3,50.1,5.9C161.9,10.5,193-8.3,218.2,3.9
	c33,16,1.3,40.5,32.5,41.3c21.7,0.5,31.8-28.9,63-28.9c37.2,0,37.2,25.6,52.1,38.2c9.8,8.3,56.2,25.9,61,47
	c6.1,26.7-0.1,72.3-22.8,84.6c-47.9,26.2-63.1-7.9-85.2,24.3c-18.7,27.3-2.6,43.4-32,58.8c-16.2,8.5-35.5,12-58.3-2.6
	c-38.1-24.4-39.2-27.4-60.4-31.5c-13.9-2.7-37.5,4.2-52.6,24.8c-13.1,17.9-61.5,24.9-84.1,1.3C16.6,245.8,8.1,218.9,10.7,197
	C14.6,164.2,0,170.2,0,151.3c0-25.8,27.6-28.3,29.2-42.8c2.4-21.1-8.6-25.3-3.9-32.5C30.1,68.6,34.1,73.8,54.6,67.9z
          "
            dur="8s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
  );
};

export const Blob3 = () => {
  return (
    <div className="blob-wrapper blob3">
      <svg version="1.1" x="0px" y="0px" viewBox="0 0 356.1 209.5">
        <path>
          <animate
            attributeName="d"
            values="
M20.4,187c12,7,34.3,1.5,43.7-5.4c10.1-7.4,20.7-10.1,25.9-10.1c15.2,0.2,28.9,16.5,45.3,25.8
	c22.2,12.6,56.8,17.6,79,4.6c21.9-12.8,17.4-34.6,43.4-45.9c9.7-4.3,19.1-5,33.5-6.2c27.6-2.2,34.6,3.7,45.7-2.6
	c12-6.7,21.3-23.4,18.8-37.7c-3.6-19.9-27.4-19.2-35.6-43.4c-5-14.9,2.7-18.8-2.1-36.6c-4.2-15.5-18-19.3-33.5-20.1
	c-22.9-1.3-40.7,24.4-54.3,18.8c-12.4-5-4.3-15.9-25.2-24.5c-6.8-2.8-16.5,0.4-26.9,3.6c-10.5,3.1-29.5,12.5-41.2,10.1
	c-24-5-24-17.6-41-17.4C73.3,0.2,62.6,13.2,48.2,29C27.6,51.9,8.5,25.6,1,49.5C-3.8,65.2,9.8,90.4,12,99.7c2.8,12.2,4,20.5-2.1,33.4
	C3,147.9-12.4,168,20.4,187z;

M22.6,169.1c10.5,9.1,31.4,2,41.8-3.5c10.5-5.6,15.2,8.8,32.1,8.9c24.4,0.1,30.2-13.6,52.3-14.7
	c25.5-1.3,28.6,30.7,60,28.3c25.3-2,25.4-19.8,51.4-31.2c9.7-4.3,19.1-5,33.5-6.2c27.6-2.2,24.3-24.1,28.3-30.4
	c8.3-12.9,11.9-2.6,21-16.6c5.6-8.5,4.7-29.8-14.3-41.8c-13.3-8.4-26.2,5.5-31-12.4c-4.2-15.5,0.6-28.7-17.4-36.4
	c-18.2-7.8-29.4,11.4-45.3,8.5c-13.2-2.4-4.6-12.8-27.6-17c-7.3-1.3-16,3.4-26.9,3.6c-7.9,0.2-31-11.8-48.9-7.1
	C108,7.3,105.1,27,86.1,28.6C63.7,30.4,39,1.9,24.7,17.7c-20.6,22.9,7.4,28.7,0,52.6c-4.9,15.7-22.3,21.6-24.2,31
	C-3,119.5,12,119.8,12.5,134C12.9,148.2,13.2,160.9,22.6,169.1z;

M20.4,187c12,7,34.3,1.5,43.7-5.4c10.1-7.4,20.7-10.1,25.9-10.1c15.2,0.2,28.9,16.5,45.3,25.8
	c22.2,12.6,56.8,17.6,79,4.6c21.9-12.8,17.4-34.6,43.4-45.9c9.7-4.3,19.1-5,33.5-6.2c27.6-2.2,34.6,3.7,45.7-2.6
	c12-6.7,21.3-23.4,18.8-37.7c-3.6-19.9-27.4-19.2-35.6-43.4c-5-14.9,2.7-18.8-2.1-36.6c-4.2-15.5-18-19.3-33.5-20.1
	c-22.9-1.3-40.7,24.4-54.3,18.8c-12.4-5-4.3-15.9-25.2-24.5c-6.8-2.8-16.5,0.4-26.9,3.6c-10.5,3.1-29.5,12.5-41.2,10.1
	c-24-5-24-17.6-41-17.4C73.3,0.2,62.6,13.2,48.2,29C27.6,51.9,8.5,25.6,1,49.5C-3.8,65.2,9.8,90.4,12,99.7c2.8,12.2,4,20.5-2.1,33.4
	C3,147.9-12.4,168,20.4,187z
          "
            dur="8s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
  );
};

export const Blob4 = () => {
  return (
    <div className="blob-wrapper blob4">
      <svg version="1.1" x="0px" y="0px" viewBox="0 0 444 300" width="438">
        <path>
          <animate
            attributeName="d"
            values="
M76.7,47.7c-3.4,30.8-30.6,31.5-50.1,44.9c-10.5,7.2-31.1,12.2-25.8,50.1c5.5,39.3,29.9,22.7,33.5,42.8
	c3.6,19.8-20.9,17.6-27.9,36.6c-5.5,15-6.1,40,7.7,49.5c20.9,14.5,34.8-7.2,58.8,0.5c14.6,4.7,24.5,17.4,37.7,20.6
	c6.3,1.5,34,8.3,55.7-4.6c24.4-14.6,17-39.7,39.2-49.5c14.9-6.6,19.4,4,45.4,1.5c28.4-2.7,30.8-19,63-25.3c25.4-5,31.7,4.7,47.5,1
	c51-12,83.6-123.9,34.6-152.8c-19-11.2-21.2,6.2-49-13.9c-19-13.7-11.4-34.1-27.4-43.3c-14.9-8.6-37.1-12.2-76.9,20.6
	c-20.6,17-58.4,16.5-63,16c-42.5-4.3-32.5-36.6-68.1-35.6C98.1,7.3,79.3,24.5,76.7,47.7z;
M81.9,35.7C68.6,63.8,57.6,63.3,36.6,72.5C14.6,82.1-2.3,91.6,0.2,129.8c1.9,29.4,30.1,30.1,44.5,44.5
	c6.6,6.6,8.9,11.1,1.9,30.2c-5.5,15-5.8,39.6,7.7,49.5c21.7,15.9,27.5-8.5,51.5-0.8c14.6,4.7,12.1,21.6,23.1,29.6
	c16.3,11.9,39.2,10.7,55.7-4.6C205.7,258.8,205,249,222,244c17.3-5.1,31.4,31,66.6,18.2c43.5-15.8,11.6-51,43.8-57.3
	c25.4-5,37.9,4.9,53.8,1.9c54.2-10.1,81.2-105.4,32.1-134.3c-19-11.2-25-13.1-52.9-33.3c-19-13.7-28.8-26.3-50.9-30.6
	c-16.9-3.3-44.5,13.2-57.3,27.1c-24.9,27.2-59.2-7.9-72.8-17.4c-35-24.5-53.4-19-68.9-13.9C102.5,8.6,88.9,21,81.9,35.7z;
M76.7,47.7c-3.4,30.8-30.6,31.5-50.1,44.9c-10.5,7.2-31.1,12.2-25.8,50.1c5.5,39.3,29.9,22.7,33.5,42.8
	c3.6,19.8-20.9,17.6-27.9,36.6c-5.5,15-6.1,40,7.7,49.5c20.9,14.5,34.8-7.2,58.8,0.5c14.6,4.7,24.5,17.4,37.7,20.6
	c6.3,1.5,34,8.3,55.7-4.6c24.4-14.6,17-39.7,39.2-49.5c14.9-6.6,19.4,4,45.4,1.5c28.4-2.7,30.8-19,63-25.3c25.4-5,31.7,4.7,47.5,1
	c51-12,83.6-123.9,34.6-152.8c-19-11.2-21.2,6.2-49-13.9c-19-13.7-11.4-34.1-27.4-43.3c-14.9-8.6-37.1-12.2-76.9,20.6
	c-20.6,17-58.4,16.5-63,16c-42.5-4.3-32.5-36.6-68.1-35.6C98.1,7.3,79.3,24.5,76.7,47.7z
          "
            dur="8s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
  );
};

export const Blob5 = () => {
  return (
    <div className="blob-wrapper blob5">
      <svg version="1.1" x="0px" y="0px" viewBox="0 0 480 302.9" width="490">
        <path>
          <animate
            attributeName="d"
            values="M53,54.8c2.4-11.5,11.1-40.7,37.2-50.4c19.3-7.2,51.5-6.1,68.9,8.1c17,13.9,10.7,40.1,35.6,46.5
	c12.4,3.2,31.3-2.5,43.4-18.6c7-9.3,30.6-29.6,56.2-21.5c16.6,5.3,16.8,21.3,26.7,37.8c10.5,17.6,21,27.5,47.2,15.5
	c9.9-4.5,31.5-15.1,39.5-17.3c26.3-7.3,47.2,12.5,47.2,27.2c0,13.9-5.5,28.1-20.2,37.2c-12.6,7.8-15.4,20.4-9.2,30.1
	c10.8,17,13.7,30.1,1.5,37.4c-17.3,10.3-34.1-7.7-50.3,21.7c-4.4,7.9,1.7,17.5,7.7,32.5c5.5,13.7,1.5,31.7-28.6,38.7
	c-38.7,8.9-47.9-24.7-94.5-40.9c-38.5-13.3-84.3,7.6-104.4,23c-42.3,32.4-73.5,52.6-108.4,34.1c-41.7-22.2-8.8-82.7-14.7-110.6
	c-3.9-18.6-47.2-33.7-29.4-79.7C14.3,79.5,46.7,84.5,53,54.8z;
M52.7,34.4c24.8-27.1,44.4,9.8,70.5,0c19.3-7.2,25.5-23.2,52.6-18.6c21.7,3.7,14.5,15.3,39.5,21.6
	c12.4,3.2,16.8-11.5,31-25.7c18.6-18.6,51.4-14.7,65.8,7.9c7.7,12.2,3,13.1,20.9,19.9c22.5,8.6,25.1-18.5,52.8-10.5
	c28.5,8.2,14.6,21.2,26.2,30.7c17.4,14.2,37.6-7,49.5,1.5c17.2,12.2-12.5,29.3-7,45.7c5.5,16.5,24.2,31.7,20.1,42.6
	c-8.5,22.7-45.5-2.6-64.3,4.1c-18.9,6.7-16.1,23-13.2,33.2c4.5,15.6,17.8,24.8-2.3,38c-23.4,15.3-59.6-27.1-78.2-9.3
	c-28.7,27.5-16.6,72.8-70,68.6c-40.6-3.2-45.4-67-69.4-74c-51.1-14.8-67.4,34.1-99.1-4.6C48.3,169-2.3,189.9,0.1,161.4
	c3.1-37.2,41-19.8,58.8-65.8C68.9,69.7,32.2,56.9,52.7,34.4z;

M53,54.8c2.4-11.5,11.1-40.7,37.2-50.4c19.3-7.2,51.5-6.1,68.9,8.1c17,13.9,10.7,40.1,35.6,46.5
	c12.4,3.2,31.3-2.5,43.4-18.6c7-9.3,30.6-29.6,56.2-21.5c16.6,5.3,16.8,21.3,26.7,37.8c10.5,17.6,21,27.5,47.2,15.5
	c9.9-4.5,31.5-15.1,39.5-17.3c26.3-7.3,47.2,12.5,47.2,27.2c0,13.9-5.5,28.1-20.2,37.2c-12.6,7.8-15.4,20.4-9.2,30.1
	c10.8,17,13.7,30.1,1.5,37.4c-17.3,10.3-34.1-7.7-50.3,21.7c-4.4,7.9,1.7,17.5,7.7,32.5c5.5,13.7,1.5,31.7-28.6,38.7
	c-38.7,8.9-47.9-24.7-94.5-40.9c-38.5-13.3-84.3,7.6-104.4,23c-42.3,32.4-73.5,52.6-108.4,34.1c-41.7-22.2-8.8-82.7-14.7-110.6
	c-3.9-18.6-47.2-33.7-29.4-79.7C14.3,79.5,46.7,84.5,53,54.8z
          "
            dur="8s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
  );
};
