import * as React from "react";

import OnTheSide from "./images/on_the_side.svg";
import ForkIcon from "./images/fork_icon.png";
import KnifeIcon from "./images/knife_icon.png";
import PlantyGrowth from "./images/growth.gif";
import PlantyJealous from "./images/jealous_cropped.gif";
import PlantyRootity from "./images/rootity.gif";
import PlantyWater from "./images/water.gif";
import Furby from "./images/furby.gif";
import Pasta from "./images/pasta.gif";
import Pigs from "./images/pigs.gif";
import Art from "./images/art.gif";

const Sidebar = ({ sideBarExpanded, toggleSidebar }) => {
  return (
    <>
      <div className={`sidebar ${sideBarExpanded ? "expanded" : ""}`}>
        <div className={`sidebar-content ${sideBarExpanded ? "visible" : ""}`}>
          <div className="sidebar-header">I enjoy....</div>
          <div className="sidebar-section">
            <div className="title">Making new friends</div>
            <div className="subtitle">
              Quarantine got lonely so I built myself a digital plant friend
              with ARKit and SwiftUI.
            </div>
            <div className="image-container">
              <img src={PlantyGrowth} className="sidebar-image" />
              <img src={PlantyJealous} className="sidebar-image" />
              <img src={PlantyWater} className="sidebar-image" />
              <img src={PlantyRootity} className="sidebar-image" />
            </div>
          </div>
          <div className="sidebar-section">
            <div className="title">Destroying Furby</div>
            <div className="subtitle">
              I've been tinkering with a Furby hack involving a Raspberry Pi
              Zero, Google's Speech-To-Text library, and ffmpeg's audio filters.
            </div>
            <img src={Furby} className="image-container sidebar-image" />
          </div>
          <div className="sidebar-section">
            <div className="title">Doodling</div>
            <div className="subtitle">
              I love to draw, paint, and play around with new creative tools in
              my spare time.
            </div>
            <img src={Art} className="image-container pasta-image" />
          </div>
          <div className="sidebar-section">
            <div className="title">Archiving</div>
            <div className="subtitle">
              Here's an even weirder site I made with some projects I was
              exploring about a year ago.
            </div>
            <a href="https://lolio.page" target="_blank" rel="noreferrer">
              <img
                src={Pigs}
                className="image-container pasta-image image-link"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="sidebar-column">
        <div
          className={`sidebar-icons ${sideBarExpanded ? "expanded" : ""}`}
          onClick={toggleSidebar}
        >
          <img src={ForkIcon} className="fork-icon" />
          <img src={KnifeIcon} className="knife-icon" />
        </div>
        <img
          src={OnTheSide}
          alt="on the side"
          className="on-the-side"
          onClick={toggleSidebar}
        />
      </div>
    </>
  );
};
export default Sidebar;
