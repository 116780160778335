import * as React from "react";
import "./App.css";
import Experience from "./Experience";
import Sidebar from "./SideProjects";

function App() {
  const [sideBarExpanded, setSideBarExpanded] = React.useState(false);
  const toggleSidebar = () => setSideBarExpanded(!sideBarExpanded);
  return (
    <div className="App">
      <div className="container">
        <Experience pagingEnabled={!sideBarExpanded} />
      </div>
      <div className="right-container">
        <Sidebar
          sideBarExpanded={sideBarExpanded}
          toggleSidebar={toggleSidebar}
        />
      </div>
    </div>
  );
}

export default App;
