import Scene from "./Scene";

import BoweryVideo from "./images/bowery.mov";
import BoweryVideoPoster from "./images/bowery-poster.jpeg";
import Mouth from "./images/mouth.svg";
import MouthClosed from "./images/mouth-closed.svg";
import MURAL from "./images/mural-smile.gif";

import IDEOVideo from "./images/IDEO-montage.mov";
import IDEOPoster from "./images/IDEO-poster.png";
import { Blob1, Blob2, Blob3, Blob4, Blob5 } from "./Blobs";

import React from "react";

const FADE_TIMEOUT = 200;
const NUM_PAGES = 5;
const TOTAL_DEGREES = 360;
const PROGRESS_RADIUS = 40;

const Experience = ({ pagingEnabled }) => {
  const [currentPageIndex, setCurrentPageIndex] = React.useState(null);
  const [progress, setProgress] = React.useState(0);
  const [isSettingPageIndex, setIsSettingPageIndex] = React.useState(false);
  const [started, setStarted] = React.useState(false);

  const classNameForPageWithIndex = (baseClassName, index) => {
    return currentPageIndex === index
      ? `${baseClassName} current`
      : baseClassName;
  };

  const goToNextPage = () => {
    if (!pagingEnabled) return;
    if (currentPageIndex === null && !isSettingPageIndex) {
      setCurrentPageIndex(0);
      setProgress(TOTAL_DEGREES / NUM_PAGES);
      setStarted(true);
      return;
    }
    setIsSettingPageIndex(true);
    setCurrentPageIndex(null);
    setTimeout(() => {
      if (currentPageIndex === NUM_PAGES - 1) {
        setCurrentPageIndex(null);
        setProgress(0);
        setStarted(false);
      } else {
        setCurrentPageIndex(currentPageIndex + 1);
        setProgress(((currentPageIndex + 2) * TOTAL_DEGREES) / NUM_PAGES);
      }
      setIsSettingPageIndex(false);
    }, FADE_TIMEOUT);
  };

  const progressCircumference = PROGRESS_RADIUS * 2 * Math.PI;

  return (
    <div className="experience-container">
      <div className="sticky-header">Lauren Shapiro</div>
      <div className="font-attribution">
        fonts by{" "}
        <a href="https://ohnotype.co/" target="_blank">
          @ohnotypeco
        </a>
        , loading gif by{" "}
        <a href="https://giphy.com/snickars" target="_blank">
          @snickars
        </a>
      </div>
      <div className="mouth-container">
        {/* https://css-tricks.com/building-progress-ring-quickly/ */}
        <svg
          viewBox="0 0 88 88"
          width="88"
          height="88"
          className="mouth-progress"
        >
          <circle
            className="progress-ring-circle"
            strokeWidth="4"
            fill="transparent"
            r="40"
            cx="44"
            cy="44"
            strokeDasharray={`${progressCircumference} ${progressCircumference}`}
            strokeLinecap="round"
            strokeDashoffset={
              progressCircumference -
              (progress / TOTAL_DEGREES) * progressCircumference
            }
          />
        </svg>
        <img src={progress === 0 ? MouthClosed : Mouth} className="mouth" />
      </div>
      <Scene
        currentPageIndex={currentPageIndex}
        shouldBurgerOpen={started}
        onClick={goToNextPage}
      />
      <div className="experience">
        <div className="mobile-experience">
          <div className="mobile-text">
            Hi, I’m Lauren. I'm a software generalist interested in art and
            animation. Currently making shapes at{" "}
            <a
              href="https://bezel.it"
              className="bold"
              target="_blank"
              rel="noreferrer"
            >
              Bezel
            </a>
            .
          </div>
          <div className="mobile-text">
            Previously, I was stackin' stickies at{" "}
            <a
              href="https://www.mural.co/"
              className="bold"
              target="_blank"
              rel="noreferrer"
            >
              MURAL
            </a>
            , while pursuing personal projects on the side.
          </div>
          <div className="mobile-text">
            Before MURAL, I made tools to grow lots of lettuce at{" "}
            <a
              href="https://boweryfarming.com/"
              className="bold"
              target="_blank"
            >
              Bowery Farming
            </a>
            .
          </div>
          <div className="mobile-text">
            And before that, I did a lot of weird stuff as a Software Developer
            at{" "}
            <a
              href="https://www.ideo.com/"
              className="bold"
              target="_blank"
              rel="noreferrer"
            >
              IDEO
            </a>
            .
          </div>
        </div>
        <div className="experience-content">
          <div className="experience-column">
            <div className={classNameForPageWithIndex("experience-page", 0)}>
              <Blob1 />
              <div className="experience-text" style={{ textAlign: "center" }}>
                Hi, I’m Lauren.
              </div>
            </div>
            <div className={classNameForPageWithIndex("experience-page", 1)}>
              <Blob2 />
              <div className="experience-text">
                Web development is my bread and butter. Currently making shapes
                at{" "}
                <a
                  href="https://bezel.it"
                  className="bold"
                  target="_blank"
                  rel="noreferrer"
                >
                  Bezel
                </a>
                .
              </div>
            </div>
            <div className={classNameForPageWithIndex("experience-page", 2)}>
              <Blob3 />
              <div className="experience-text">
                Previously, I was stackin' stickies at{" "}
                <a
                  href="https://www.mural.co/"
                  className="bold"
                  target="_blank"
                  rel="noreferrer"
                >
                  MURAL
                </a>
                , while pursuing personal projects on the side.
              </div>
            </div>
            <div className={classNameForPageWithIndex("experience-page", 3)}>
              <Blob4 />
              <div className="experience-text">
                Before MURAL, I made tools to grow lots of lettuce at{" "}
                <a
                  href="https://boweryfarming.com/"
                  className="bold"
                  target="_blank"
                  rel="noreferrer"
                >
                  Bowery Farming
                </a>
                .
              </div>
            </div>
            <div className={classNameForPageWithIndex("experience-page", 4)}>
              <Blob5 />
              <div className="experience-text">
                And before that, I did a lot of weird stuff as a Software
                Developer at{" "}
                <a
                  href="https://www.ideo.com/"
                  className="bold"
                  target="_blank"
                  rel="noreferrer"
                >
                  IDEO
                </a>
                .
              </div>
            </div>
          </div>
          <div className="experience-column">
            <div className={classNameForPageWithIndex("image", 2)}>
              <img src={MURAL} className="media mural" />
            </div>
            <div className={classNameForPageWithIndex("image", 3)}>
              <video
                playsInline
                muted
                autoPlay
                loop
                poster={BoweryVideoPoster}
                className="media bowery-image"
              >
                <source src={BoweryVideo} />
              </video>
            </div>
            <div className={classNameForPageWithIndex("image", 4)}>
              <video
                playsInline
                muted
                autoPlay
                loop
                poster={IDEOPoster}
                className="media"
              >
                <source src={IDEOVideo} />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Experience;
