import * as React from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import hdr from "./images/rooitou_park_1k.hdr";
import Loading from "./images/loading.svg";

import * as THREE from "three";
import burgerScene from "./models/burger-out.glb";

const TOP_BUN = "Top_Bun";
const BOTTOM_BUN = "Bottom_Bun";
const LETTUCE = "Lettuce";
const CHEESE = "Cheese";
const PATTY = "WavyPatty";
const BURGER_PARTS = [TOP_BUN, CHEESE, LETTUCE, PATTY];

const BURGER_ANIMATION_POSITIONS = {
  [TOP_BUN]: {
    start: 1.9967072010040283,
    end: 4.2,
  },
  [CHEESE]: {
    start: -0.25238269567489624,
    end: 1.3,
  },
  [LETTUCE]: {
    start: -0.18358033895492554,
    end: 0.75,
  },
  [BOTTOM_BUN]: {
    start: 0.6107425689697266,
    end: -0.55,
  },
};

const usePrevious = (value) => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const SceneLoading = ({ loading }) => {
  const [isVisible, setIsVisible] = React.useState(loading);
  React.useEffect(() => {
    if (!loading && isVisible) {
      setTimeout(() => {
        setIsVisible(false);
      }, 1000);
    }
  }, [loading]);

  return isVisible ? (
    <div className={`loading ${loading ? "visible" : ""}`}>
      <img
        className="loading-gif"
        src="https://media.giphy.com/media/YlG5flOckA9mxBZXso/giphy.gif"
      />
      <img src={Loading} className="loading-text" />
    </div>
  ) : null;
};

const Scene = ({ currentPageIndex, shouldBurgerOpen, onClick }) => {
  const [loading, setIsLoading] = React.useState(true);
  const mountElementRef = React.useRef(null);
  const prevTimeRef = React.useRef(0);
  const frameIdRef = React.useRef(0);

  const topBunRef = React.useRef(null);
  const bottomBunRef = React.useRef(null);
  const cheeseRef = React.useRef(null);
  const lettuceRef = React.useRef(null);
  const pattyRef = React.useRef(null);

  const cameraRef = React.useRef(null);
  const rendererRef = React.useRef(null);
  const sceneRef = React.useRef(null);

  let isBurgerOpenRef = React.useRef(false);
  let isBurgerTransitioning = React.useRef(false);
  let shouldBurgerOpenRef = React.useRef(shouldBurgerOpen);

  let currentPageIndexRef = React.useRef(currentPageIndex);
  let animationStartTimeRef = React.useRef(null);
  let burgerPositionsRef = React.useRef({
    [TOP_BUN]: { x: 0, y: 0, z: 0 },
    [CHEESE]: { x: 0, y: 0, z: 0 },
    [LETTUCE]: { x: 0, y: 0, z: 0 },
    [PATTY]: { x: 0, y: 0, z: 0 },
    [BOTTOM_BUN]: { x: 0, y: 0, z: 0 },
  });

  const prevPageIndex = usePrevious(currentPageIndexRef);

  React.useEffect(() => {
    shouldBurgerOpenRef.current = shouldBurgerOpen;
    if (shouldBurgerOpen !== isBurgerOpenRef.current) {
      isBurgerTransitioning.current = true;
    }
  }, [shouldBurgerOpen]);

  React.useEffect(() => {
    if (prevPageIndex && prevPageIndex.current) {
      const previousBurgerPart = burgerPartForIndex(prevPageIndex.current);
      if (previousBurgerPart) {
        const resetPosition =
          burgerPositionsRef.current[previousBurgerPart.name];
        previousBurgerPart.position.x = resetPosition.x;
        previousBurgerPart.position.y =
          isBurgerOpenRef.current && previousBurgerPart.name !== PATTY
            ? BURGER_ANIMATION_POSITIONS[previousBurgerPart.name].end
            : resetPosition.y;
        previousBurgerPart.position.z = resetPosition.z;
        previousBurgerPart.rotation.z = 0;
      }
    }

    currentPageIndexRef.current = currentPageIndex;
  }, [currentPageIndex]);

  const getCubeMapTexture = async (pmremGenerator) => {
    return new Promise((resolve, reject) => {
      new RGBELoader().setDataType(THREE.UnsignedByteType).load(
        // "./rooitou_park_1k.hdr",
        hdr,
        (texture) => {
          const envMap = pmremGenerator.fromEquirectangular(texture).texture;
          pmremGenerator.dispose();

          resolve({ envMap });
        },
        undefined,
        reject
      );
    });
  };

  const burgerPartForIndex = (index) => {
    const burgerPart = BURGER_PARTS[index - 1];
    switch (burgerPart) {
      case TOP_BUN:
        if (topBunRef && topBunRef.current) {
          return topBunRef.current;
        }
        break;
      case CHEESE:
        if (cheeseRef && cheeseRef.current) {
          return cheeseRef.current;
        }
        break;
      case LETTUCE:
        if (lettuceRef && lettuceRef.current) {
          return lettuceRef.current;
        }
        break;
      case PATTY:
        if (pattyRef && pattyRef.current) {
          return pattyRef.current;
        }
        break;
      default:
        return null;
    }
  };

  const jiggleBurgerPart = (burgerPart, time) => {
    if (!isBurgerOpenRef.current) {
      return;
    }
    burgerPart.rotation.z = Math.sin(time * 0.01) * Math.PI * 0.02;
    burgerPart.position.y += Math.sin(time * 0.01) * 0.02;
  };

  const rotateBurger = (time) => {
    const dt = time - prevTimeRef.current;
    const rotationToAdd = dt * Math.PI * 0.0001;

    [topBunRef, bottomBunRef, cheeseRef, lettuceRef, pattyRef].forEach(
      (burgerPart) => {
        if (burgerPart && burgerPart.current) {
          burgerPart.current.rotation.y += rotationToAdd;
        }
      }
    );
  };

  const easeInBack = (x) => {
    const c1 = 1.70158;
    const c3 = c1 + 1;
    return c3 * Math.pow(x, 3) - c1 * Math.pow(x, 2);
  };

  const easeOutCubic = (x) => {
    return 1 - Math.pow(1 - x, 3);
  };

  const animateBurgerPart = (burgerPart, time, reverse) => {
    if (burgerPart && burgerPart.current) {
      const deltaSeconds = (time - animationStartTimeRef.current) * 0.001;
      const animationStart =
        BURGER_ANIMATION_POSITIONS[burgerPart.current.name].start;
      const animationEnd =
        BURGER_ANIMATION_POSITIONS[burgerPart.current.name].end;

      const currentStartPosition = reverse ? animationEnd : animationStart;
      const destination = reverse ? animationStart : animationEnd;
      const currentDelta =
        currentStartPosition > destination
          ? burgerPart.current.position.y - destination
          : destination - burgerPart.current.position.y;
      if (currentDelta > 0.000000001) {
        const x = Math.min(deltaSeconds * 3, 1);
        const moveAmount = reverse ? easeOutCubic(x) : easeInBack(x);
        const totalDelta = reverse
          ? animationStart - animationEnd
          : animationEnd - animationStart;

        const potentialNextMove =
          currentStartPosition + totalDelta * moveAmount;
        if (
          Math.abs(burgerPart.current.position.y - potentialNextMove) >
          Math.abs(burgerPart.current.position.y - destination)
        ) {
          burgerPart.current.position.y = destination;
        } else {
          burgerPart.current.position.y = potentialNextMove;
        }
        return true;
      }
    }
    return false;
  };

  const animateBurgerOpen = (time, reverse) => {
    const moves = [topBunRef, cheeseRef, lettuceRef, bottomBunRef].map(
      (burgerPart) => animateBurgerPart(burgerPart, time, reverse)
    );
    if (moves.every((m) => m === false)) {
      isBurgerTransitioning.current = false;
      isBurgerOpenRef.current = !reverse;
      animationStartTimeRef.current = null;
    }
  };

  const renderScene = (time) => {
    rotateBurger(time);
    if (isBurgerTransitioning.current) {
      if (animationStartTimeRef.current == null) {
        animationStartTimeRef.current = time;
      }
      animateBurgerOpen(time, !shouldBurgerOpenRef.current);
    } else if (currentPageIndexRef.current) {
      const currentBurgerPart = burgerPartForIndex(currentPageIndexRef.current);
      jiggleBurgerPart(currentBurgerPart, time);
    }

    if (rendererRef && rendererRef.current) {
      rendererRef.current.render(sceneRef.current, cameraRef.current);
      prevTimeRef.current = time;
    }
  };

  const animate = (time) => {
    renderScene(time);
    frameIdRef.current = requestAnimationFrame(animate);
  };

  const start = () => {
    if (!frameIdRef || !frameIdRef.current) {
      frameIdRef.current = requestAnimationFrame(animate);
    }
  };

  const stop = () => {
    cancelAnimationFrame(frameIdRef.current);
    frameIdRef.current = null;
  };

  const handleResize = () => {
    if (
      mountElementRef &&
      mountElementRef.current &&
      cameraRef.current &&
      rendererRef.current
    ) {
      const newWidth = mountElementRef.current.clientWidth;
      const newHeight = mountElementRef.current.clientHeight;
      cameraRef.current.aspect = newWidth / newHeight;
      cameraRef.current.updateProjectionMatrix();
      rendererRef.current.setSize(newWidth, newHeight);
    }
  };

  React.useEffect(() => {
    const mountEl = mountElementRef.current;
    const width = mountEl.clientWidth;
    const height = mountEl.clientHeight;

    const scene = new THREE.Scene();
    sceneRef.current = scene;
    //scene.background = new THREE.Color(0xffffff);

    const camera = new THREE.PerspectiveCamera(45, width / height, 0.1, 100);
    cameraRef.current = camera;
    camera.position.set(0, 3, 15);
    camera.lookAt(0, 3, 0);

    const hemiLight = new THREE.HemisphereLight();
    hemiLight.name = "hemi_light";
    scene.add(hemiLight);

    const light1 = new THREE.AmbientLight(0xffffff, 0.3);
    light1.name = "ambient_light";
    camera.add(light1);

    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setClearColor(0x000000, 0);

    rendererRef.current = renderer;

    renderer.physicallyCorrectLights = true;
    renderer.toneMappingExposure = 1.0;

    const pmremGenerator = new THREE.PMREMGenerator(renderer);
    pmremGenerator.compileEquirectangularShader();
    getCubeMapTexture(pmremGenerator).then(({ envMap }) => {
      if (envMap != null) {
        scene.environment = envMap;
      }
    });

    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("./decoder/");
    loader.setDRACOLoader(dracoLoader);

    const loadScene = (loader) => {
      return new Promise((resolve, reject) => {
        loader.load(
          burgerScene,
          (gltf) => {
            resolve(gltf);
          },
          null,
          (error) => {
            reject(error);
          }
        );
      });
    };

    loadScene(loader).then((loadedGLTF) => {
      const loadedScene = loadedGLTF.scene;
      loadedScene.children.forEach((burgerPart) => {
        burgerPositionsRef.current[burgerPart.name].x = burgerPart.position.x;
        burgerPositionsRef.current[burgerPart.name].y = burgerPart.position.y;
        burgerPositionsRef.current[burgerPart.name].z = burgerPart.position.z;
      });

      const topBun = loadedScene.children.find((m) => m.name === TOP_BUN);
      if (topBun != null) {
        topBunRef.current = topBun;
        topBunRef.current.onAfterRender = () => setIsLoading(false);
      }

      const bottomBun = loadedScene.children.find((m) => m.name === BOTTOM_BUN);
      if (bottomBun != null) {
        bottomBunRef.current = bottomBun;
      }

      const cheese = loadedScene.children.find((m) => m.name === CHEESE);
      if (cheese != null) {
        cheeseRef.current = cheese;
      }
      scene.add(loadedScene);

      const lettuce = loadedScene.children.find((m) => m.name === LETTUCE);
      if (lettuce != null) {
        lettuceRef.current = lettuce;
      }
      scene.add(loadedScene);

      const patty = loadedScene.children.find((m) => m.name === PATTY);
      if (patty != null) {
        pattyRef.current = patty;
        pattyRef.current.material.combine = 1;
      }

      scene.add(loadedScene);
    });

    mountElementRef.current.appendChild(renderer.domElement);
    renderer.setSize(width, height);
    start();
    window.addEventListener("resize", handleResize);

    return () => {
      stop();
      window.removeEventListener("resize", handleResize);
      mountEl.removeChild(renderer.domElement);
    };
  }, []);

  return (
    <>
      <SceneLoading loading={loading} />
      <div ref={mountElementRef} className="scene" onClick={onClick}></div>
    </>
  );
};

export default Scene;
